<template>
  <div>
    <h4 class="title has-text-centered is-4">Search</h4>

    <form @submit="handleSubmit">
      <b-field grouped>
        <b-field label="Furniture ID" custom-class="is-small">
          <b-input v-model="formData.id" size="is-small"></b-input>
        </b-field>

        <b-field label="Name" custom-class="is-small">
          <b-input v-model="formData.name" size="is-small"></b-input>
        </b-field>

        <b-field label="Category" custom-class="is-small">
          <b-select v-model="formData.category_id" size="is-small">
            <option></option>
            <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
          </b-select>
        </b-field>

        <b-field label="Min Quantity" custom-class="is-small">
          <b-input v-model="formData.min_quantity" size="is-small"></b-input>
        </b-field>

        <b-field label="Max Quantity" custom-class="is-small">
          <b-input v-model="formData.max_quantity" size="is-small"></b-input>
        </b-field>
      </b-field>

      <b-field grouped>
        <b-field label="Currency" custom-class="is-small">
          <b-select v-model="formData.price_type" size="is-small">
            <option value="monetti">Monetti</option>
            <option value="currants">Currants</option>
          </b-select>
        </b-field>

        <b-field label="Min Price" custom-class="is-small">
          <b-input v-model="formData.min_price" size="is-small"></b-input>
        </b-field>

        <b-field label="Max Price" custom-class="is-small">
          <b-input v-model="formData.max_price" size="is-small"></b-input>
        </b-field>
      </b-field>

      <b-button type="is-info" icon-left="search" native-type="submit" size="is-small">Search</b-button>
    </form>

  </div>
</template>

<script>
import { Furniture } from '@/services/api';

export default {
  name: 'furniture-search-form',
  data() {
    return {
      errors: [],
      formData: {
        ...this.$route.query,
      },
      loadingForm: false,
      sent: false,
      categories: [],
    };
  },
  mounted() {
    Furniture.getFurnitureCategories()
      .then((data) => {
        this.categories = data;
      });
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();

      this.errors = [];
      this.loadingForm = true;

      const params = {
        ...this.formData,
        page: 1,
      };

      const queryString = Object.keys(params).map((key) => `${key}=${params[key] || ''}`).join('&');

      this.$router.push({ path: `/furniture?${queryString}` });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
