const isJPG = (type) => type === 'locations'
  || type === 'regions';

// eslint-disable-next-line import/prefer-default-export
export const getEncyclopediaImageUrl = (type, id, thumb = false) => {
  const extension = isJPG(type) ? 'jpg' : 'png';

  return `${process.env.VUE_APP_CDN_URL}encyclopedia/${type}${thumb ? '/thumbs' : ''}/${id}.${extension}`;
};

export const getCraftingImageUrl = (type, id) => `${process.env.VUE_APP_CDN_URL}crafting/${type}/${id}.png`;

export const getGeneralImageUrl = (file) => `${process.env.VUE_APP_CDN_URL}images/${file}`;

export const getShrineImageUrl = (variation, id) => `${process.env.VUE_APP_CDN_URL}encyclopedia/inhabitants/npc_shrine_${variation}_${id}.png`;
