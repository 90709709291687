<template>
  <section class="section">
    <furniture-search-form></furniture-search-form>

    <h4 class="title has-text-centered is-4">Furniture</h4>
    <b-table
      :data="furniture.data"
      :striped="true"
      :hoverable="true"
      :loading="loadingFurniture"
      class="mt-4 clickable-table user-table"
      @click="goToFurniture"
    >
      <b-table-column field="id" label="Image" v-slot="props" centered>
        <img :src="imageSource(props.row.id)" class="furniture-image" />
      </b-table-column>
      <b-table-column field="id" label="ID" v-slot="props">
        <p>
          {{ props.row.id }}
        </p>
      </b-table-column>

      <b-table-column field="category_id" label="Category" v-slot="props">
        <p>{{ props.row.category.name }}</p>
      </b-table-column>

      <b-table-column field="name" label="Name" v-slot="props">
        <p>{{ props.row.name }}</p>
      </b-table-column>

      <b-table-column field="quantity" label="Quantity" v-slot="props">
        <p>{{ props.row.quantity }}</p>
      </b-table-column>

      <b-table-column field="currants_price" label="Price" v-slot="props">
        <p v-if="props.row.currants_price">{{ props.row.currants_price }} c</p>
        <p v-else><strong>{{ props.row.monetti_price }} M</strong></p>
      </b-table-column>
    </b-table>

    <b-pagination
      class="mt-4"
      v-model="furniture.current_page"
      :total="furniture.total"
      :per-page="furniture.per_page"
      range-before="3"
      range-after="3"
      order="is-centered"
      icon-pack="fas"
      @change="handlePageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page">
    </b-pagination>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import FurnitureSearchForm from '@/components/Furniture/FurnitureSearchForm.vue';
import { getEncyclopediaImageUrl } from '../../services/encyclopedia';

export default {
  name: 'Transactions',
  components: { FurnitureSearchForm },
  computed: {
    ...mapState('furniture', ['loadingFurniture', 'furniture']),
  },
  mounted() {
    this.loadFurniture();
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.query': function () {
      this.loadFurniture();
    },
  },
  methods: {
    loadFurniture() {
      this.$store.dispatch('furniture/loadFurniture', this.$route.query);
    },
    goToFurniture(row) {
      this.$router.push({ path: `/furniture/${row.id}` });
    },
    handlePageChange(pageNum) {
      const params = {
        ...this.$route.query,
        page: pageNum,
      };
      const queryString = Object.keys(params).map((key) => `${key}=${params[key] || ''}`).join('&');

      this.$router.push({ path: `/furniture?${queryString}` });
    },
    imageSource(id) {
      return getEncyclopediaImageUrl('furniture', id, false);
    },
  },
};
</script>

<style>
.user-table {
  font-size: 12px;
}
.furniture-image {
  max-height: 40px;
  max-width: 40px;
}
</style>
